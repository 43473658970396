/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Flex, Image } from '@chakra-ui/react';
import { Table, Tbody, Tr, Td, Box, Text, Tooltip } from '@chakra-ui/react';
import UserAPI from '../../services/api/user';
import { TableHeader } from './TableHeader';
import UserListFooter from './UserListFooter';
import { User } from '../../stores/Users/types.user';
import UserHeader from './UserHeader';
import * as Icons from '../../../assets';
import UserListSearchAndFilters from './UserListSearchAndFilters';

export const getTruncatedText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

export default function UserListTable() {
  const TABLE_COLUMNS = [
    {
      title: 'Username',
      getValue: ({ username }: User) => username?.toString() || 'NA',
    },
    {
      title: 'First Name',
      getValue: ({ firstName }: User) => firstName?.toString() || 'NA',
    },
    {
      title: 'Last Name',
      getValue: ({ lastName }: User) => lastName?.toString() || 'NA',
    },
    {
      title: 'Email',
      getValue: ({ email }: User) => email?.toString() || 'NA',
    },
    {
      title: 'Phone',
      getValue: ({ phoneNumber }: User) => phoneNumber?.toString() || 'NA',
    },
    {
      title: 'Facility',
      getValue: ({ userDetails }: User) => {
        if (userDetails && userDetails.length > 0 && userDetails[0].facility) {
          return userDetails[0].facility || 'NA';
        }
        return 'NA';
      },
    },
    {
      title: 'Specialty',
      getValue: ({ userDetails }: User) => {
        if (userDetails && userDetails.length > 0 && userDetails[0].specialties) {
          return userDetails[0].specialties.map((spec) => <div key={spec.specialty}>{spec.specialty}</div>) || 'NA';
        }
        return 'NA';
      },
    },
    {
      title: 'Role',
      getValue: ({ userDetails }: User) => {
        if (userDetails && userDetails.length > 0 && userDetails[0].specialties) {
          return userDetails[0].specialties.map((spec) => <div key={spec.role}>{spec.role}</div>) || 'NA';
        }
        return 'NA';
      },
    },
    {
      title: 'Authentication',
      getValue: ({ authentication }: User) => authentication?.toString() || 'NA',
    },
    {
      title: 'Edit',
      getValue: ({ id }: User) => {
        return (
          <Image
            _hover={{ cursor: 'pointer' }}
            src={Icons.editIcon}
            w="13px"
            h="12px"
            onClick={() => navigate(`/edit-user/${id}`)}
          />
        );
      },
    },
  ];
  const userAPI = new UserAPI();
  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState([0]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const itemsPerPage = 12;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const navigate = useNavigate();

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await userAPI.getAllUser(itemsPerPage, (currentPage - 1) * itemsPerPage);
      setUsers(fetchedUsers.results);
      setTotalCount(fetchedUsers.count);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  React.useEffect(() => {
    !isFiltered && fetchUsers();
  }, [currentPage, isFiltered]);

  const actionButtons = [
    {
      title: 'Add Users from .CSV',
      variant: 'outline',
      onClick: 'AddCsvUsers',
    },
    {
      title: 'Add Exo User',
      variant: 'outline',
      onClick: () => navigate('/create-user'),
    },
  ];

  return (
    <Flex direction={'column'} onClick={() => setShowSuggestions(false)}>
      <Box
        overflowX="auto"
        maxW="100%"
        minHeight="705px"
        width="96%"
        sx={{ backgroundColor: 'white', marginLeft: '20px', marginRight: '20px', marginBottom: '25px' }}
        overflowY="auto"
      >
        <Box position={'fixed'} width={'75%'} background={'aliceblue'} zIndex={'999'}>
          <UserHeader pageTitle="User List" actionButtons={actionButtons} />
          <UserListSearchAndFilters
            setUsers={setUsers}
            setIsFiltered={setIsFiltered}
            setTotalCount={setTotalCount}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setCurrentPage={setCurrentPage}
            isFiltered={isFiltered}
            showSuggestions={showSuggestions}
            setShowSuggestions={setShowSuggestions}
          />
        </Box>
        <Box pt={'20px'}>
          <Table position={'relative'} top={'170px'} variant="simple" width="100%" overflowX="auto" minWidth="710px">
            <TableHeader columns={TABLE_COLUMNS} />
            <Tbody>
              <Tr borderBottom="1px solid rgba(44, 44, 46, 0.5)"></Tr>

              {users?.map((user) => (
                <Tr key={`user-row-${user.id}`} borderBottom="1px solid rgba(44, 44, 46, 0.5)">
                  {TABLE_COLUMNS.map((column) => (
                    <Td
                      key={`user-column-${user.id}-${column.title}`}
                      fontFamily="Helvetica"
                      fontStyle="normal"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="17.64px"
                      letterSpacing="0.2px"
                      color="rgba(44, 44, 46, 1)"
                      textAlign="left"
                    >
                      <Text>
                        {column.title === 'Email' || column.title === 'Username' || column.title === 'Last Name' ? (
                          <Tooltip label={column.getValue(user) as string}>
                            {getTruncatedText((column.getValue(user) as string) || '', 15)}
                          </Tooltip>
                        ) : column.title === 'Facility' ? (
                          column.getValue(user)
                        ) : column.title === 'Speciality' ? (
                          user.userDetails && user.userDetails.length > 0 ? (
                            user.userDetails.map((detail, index) => (
                              <React.Fragment key={`specialty-${user.id}-${index}`}>
                                {index > 0 && <br />}
                                {detail?.specialties?.map((spec, specIndex) => (
                                  <React.Fragment key={`spec-${user.id}-${index}-${specIndex}`}>
                                    {spec.specialty}
                                    {specIndex !== detail?.specialties?.length - 1 && <br />}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ))
                          ) : (
                            'NA'
                          )
                        ) : column.title === 'Role' ? (
                          user.userDetails && user.userDetails.length > 0 ? (
                            user.userDetails.map((detail, index) => (
                              <React.Fragment key={`role-${user.id}-${index}`}>
                                {index > 0 && <br />}
                                {detail?.specialties?.map((spec, specIndex) => (
                                  <React.Fragment key={`role-${user.id}-${index}-${specIndex}`}>
                                    {spec.role}
                                    {specIndex !== detail?.specialties?.length - 1 && <br />}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ))
                          ) : (
                            'NA'
                          )
                        ) : (
                          column.getValue(user)
                        )}
                      </Text>
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <UserListFooter
        itemsPerPage={itemsPerPage}
        totalCount={Number(totalCount)}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </Flex>
  );
}
