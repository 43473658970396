/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Checkbox,
  FormHelperText,
  Select,
  Grid,
  GridItem,
  Flex,
  Icon,
  Circle,
  useToast,
} from '@chakra-ui/react';
import { CloseIcon, AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import UserTabHeader from './UserTabHeader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UserAPI from '../../services/api/user';
import UserFormHeader from './UserFormHeader';
import Paper from '../Paper';
import { WHITE, SHADOW_BOX, TITLE_GREY, ADD_GREEN, PRIMARY_BLUE, ERROR_RED } from './styles';
import { UserType } from './types';
import formatError from '../../utils/formatError';
import toSnakeCase from '../../utils/toSnakeCase';

const userAPI = new UserAPI();
const defaultSpecialty = { specialty: '', role: '' };

const UserFormLabel = ({ field }) => (
  <FormLabel mb={0} color={TITLE_GREY} fontSize={12} htmlFor={field?.name}>
    {field?.label} {field?.required && <span style={{ color: ERROR_RED }}>*</span>}
  </FormLabel>
);
/**
 * userSpecialtiesOptions, userRoleOptions are for dropdown options. userDetails for editing user data
 */
type CreateUserProps = {
  isEdit?: boolean;
  userId?: string;
};

const CreateUser = ({ isEdit = false, userId = '' }: CreateUserProps) => {
  const personalDetailsFields = [
    { name: 'username', type: 'text', label: 'User name', required: true, disabled: isEdit },
    { name: 'first_name', type: 'text', label: 'First name' },
    { name: 'last_name', type: 'text', label: 'Last name' },
    { name: 'email', type: 'email', label: 'Email address', required: true },
    {
      name: 'is_staff',
      type: 'checkbox',
      label: 'Staff status',
      helperText: 'Designates whether the user can log into the admin site',
    },
    {
      name: 'is_active',
      type: 'checkbox',
      label: 'Active',
      helperText:
        'Designates whether this user should be treated as active. Unselect this instead of deleting accounts.',
    },
    {
      name: 'is_enabled',
      type: 'checkbox',
      label: 'Is enabled',
    },
    { name: 'phone_number', type: 'tel', label: 'Phone Number' },
    { name: 'hcs_provider_id', type: 'text', label: 'HCS provider ID' },
  ];
  const toast = useToast();
  const navigate = useNavigate();
  const [userSpecialtiesOptions, setUserSpecialtiesOptions] = useState([]);
  const [userRoleOptions, setUserRoleOptions] = useState([]);
  const [userDetails, setUserDetails] = useState<UserType>({});

  const getDuplicateIndex = (array, key) => {
    const validatorSet = new Set();
    for (let i = 0; i < array.length; i++) {
      const value = array[i][key];
      if (validatorSet.has(value)) {
        return i;
      }
      validatorSet.add(value);
    }
    return -1;
  };

  const validationSchemaFunc = () => {
    return Yup.object({
      username: Yup.string().required('This is a required field'),
      first_name: Yup.string(),
      last_name: Yup.string(),
      email: Yup.string().email('Invalid email').required('This is a required field'),
      is_staff: Yup.bool(),
      is_active: Yup.bool(),
      is_enabled: Yup.bool(),
      phone_number: Yup.string(),
      hcs_provider_id: Yup.string(),
      specialties: Yup.array()
        .of(
          Yup.object().shape({
            specialty: Yup.string().required('This is a required field'),
            role: Yup.string().required('This is a required field'),
          }),
        )
        .min(1),
    }).test('isDuplicate', 'errorMessage', function (value) {
      const duplicateIndex = getDuplicateIndex(value.specialties, 'specialty');
      return duplicateIndex !== -1
        ? this.createError({
            message: 'This Specialty is already selected',
            path: `specialties[${duplicateIndex}].specialty`,
          })
        : true;
    });
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      is_staff: false,
      is_active: true,
      is_enabled: true,
      phone_number: '',
      hcs_provider_id: '',
      specialties: [defaultSpecialty],
    },
    validationSchema: validationSchemaFunc(),
    onSubmit: (values) => {
      onSaveUser(values);
    },
  });

  const handleSuccess = () => {
    toast({
      title: `User ${isEdit ? "updated" : "created"}.`,
      description: `User is ${isEdit ? "updated" : "created"} successfully.`,
      position: 'top-right',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    navigate('/');
  };

  const handleFailure = (errors) => {
    const formattedErrors = toSnakeCase(errors);
    formik?.setErrors(formattedErrors);
  };

  const onSaveUser = async (payload) => {
    try {
        const { errors = {}, success = false } = isEdit ? await userAPI.editUser(userId, payload):await userAPI.createUser(payload) : ;
        success ? handleSuccess() : handleFailure(errors);
    } catch (error) {
      throw new Error(formatError(error));
    }
  };

  const actionButtons = [
    {
      title: 'Cancel',
      variant: 'outline',
      onClick: () => navigate('/'),
      color: PRIMARY_BLUE,
      borderColor: PRIMARY_BLUE,
      fontWeight: 'normal',
    },
    {
      title: 'Save',
      variant: 'solid',
      type: 'submit',
      bg: PRIMARY_BLUE,
      color: WHITE,
      fontWeight: 'normal',
    },
  ];

  const renderInputField = (field) => {
    const inputFields = {
      text: (
        <>
          <UserFormLabel field={field} />
          <Input
            disabled={field?.disabled}
            id={field?.name}
            name={field?.name}
            type={field?.type}
            variant="flushed"
            onChange={formik?.handleChange}
            value={formik?.values[field?.name]}
          />
          <FormErrorMessage>{formik?.errors[field?.name]}</FormErrorMessage>
        </>
      ),
      email: (
        <>
          <UserFormLabel field={field} />
          <Input
            id={field?.name}
            name={field?.name}
            type={field?.type}
            variant="flushed"
            onChange={formik?.handleChange}
            value={formik?.values[field?.name]}
          />
          <FormErrorMessage>{formik?.errors[field?.name]}</FormErrorMessage>
        </>
      ),
      checkbox: (
        <>
          <Checkbox
            size="sm"
            id={field?.name}
            name={field?.name}
            onChange={formik?.handleChange}
            isChecked={formik?.values[field?.name]}
          >
            {field?.label}
          </Checkbox>
          {field?.helperText && (
            <FormHelperText color={TITLE_GREY} fontSize={12} mt={0}>
              {field?.helperText}
            </FormHelperText>
          )}
        </>
      ),
      tel: (
        <>
          <UserFormLabel field={field} />
          <Input
            id={field?.name}
            name={field?.name}
            type={field?.type}
            variant="flushed"
            onChange={formik?.handleChange}
            value={formik?.values[field?.name]}
            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
            maxLength="10"
          />
          <FormErrorMessage>{formik?.errors[field?.name]}</FormErrorMessage>
        </>
      ),
    };
    return inputFields[field?.type];
  };

  const handleAddAnotherSpecialtyClick = () => {
    const updatedSpecialties = [...formik?.values.specialties, defaultSpecialty];
    formik?.setFieldValue('specialties', updatedSpecialties);
  };

  const handleSpecialtyDelete = (specialtyIndex) => {
    let updatedSpecialties = [...formik?.values.specialties];
    updatedSpecialties =
      updatedSpecialties.length > 1
        ? updatedSpecialties.filter((specialtyItem, index) => specialtyIndex !== index)
        : updatedSpecialties;
    formik?.setFieldValue('specialties', updatedSpecialties);
  };

  const handleUserSpecialtiesChange = (e, specialtyIndex) => {
    const updatedSpecialties = [...formik?.values.specialties];
    updatedSpecialties[specialtyIndex] = { ...updatedSpecialties[specialtyIndex], specialty: e.target.value };
    formik?.setFieldValue('specialties', updatedSpecialties);
  };

  const handleUserRolesChange = (e, specialtyIndex) => {
    const updatedSpecialties = [...formik?.values.specialties];
    updatedSpecialties[specialtyIndex] = { ...updatedSpecialties[specialtyIndex], role: e.target.value };
    formik?.setFieldValue('specialties', updatedSpecialties);
  };

  const fetchUserCategories = async () => {
    try {
      const { results = [] } = await userAPI.getUserCategories();
      return results;
    } catch (error) {
      return [];
    }
  };

  const fetchSpecialties = async () => {
    try {
      const { results = [] } = await userAPI.getSpecialties();
      return results;
    } catch (error) {
      return [];
    }
  };

  const fetchUserDetails = async (id) => {
    try {
      const { userDetails = {}, success = false } = await userAPI.getUserDetails(id);
      success && setUserDetails(userDetails);
    } catch (error) {
      throw new Error(formatError(error));
    }
  };

  const fetchInitialData = async () => {
    const [userCategories = [], specialties = []] = await Promise.all([fetchUserCategories(), fetchSpecialties()]);
    setUserRoleOptions(userCategories);
    setUserSpecialtiesOptions(specialties);
    if (isEdit) {
      fetchUserDetails(userId);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        username: userDetails?.username ?? '',
        first_name: userDetails?.firstName ?? '',
        last_name: userDetails?.lastName ?? '',
        email: userDetails?.email ?? '',
        is_staff: userDetails?.isStaff ?? false,
        is_active: userDetails?.isActive ?? true,
        is_enabled: userDetails?.isEnabled ?? true,
        phone_number: userDetails?.phoneNumber ?? '',
        hcs_provider_id: userDetails?.hcsProviderId ?? '',
        specialties: userDetails?.userDetails?.[0]?.specialties?.map((specialtyItem) => ({
          specialty: specialtyItem?.specialtyId,
          role: specialtyItem?.roleId,
        })) ?? [defaultSpecialty],
      });
    }
  }, [userDetails]);

  return (
    <Box maxW="100%">
      <form onSubmit={formik?.handleSubmit}>
        <Box position={'absolute'} zIndex={'999'} background={'aliceblue'} top={0} width={'79vw'}>
        <UserTabHeader pageTitle="User List" actionButtons={actionButtons} />
        </Box>
        <Paper position={'relative'} top={'50px'} backgroundColor={WHITE} boxShadow={SHADOW_BOX}>
          <UserFormHeader isEdit={isEdit} />
          <Grid mt={5}>
            <GridItem w="50%">
              {personalDetailsFields?.map((field) => (
                <FormControl
                  key={field?.name}
                  isInvalid={ formik?.touched[field?.name] && Boolean(formik?.errors[field?.name])}
                >
                  <Box mb={2}>{renderInputField(field)}</Box>
                </FormControl>
              ))}
            </GridItem>
            <GridItem>
              <Box fontSize={14} fontWeight={500} mt={5} mb={3}>
                User specialties
              </Box>
              {formik?.touched?.specialties?.length > 0 &&
                formik?.touched?.specialties[0] &&
                Boolean(formik?.errors?.specialties?.length > 0 && formik?.errors?.specialties[0]) && (
                  <Box fontSize={14} color="#E53E3E">
                    Atleast 1 specialty is required to continue
                  </Box>
                )}
              <Grid>
                {formik?.values?.specialties?.map((specialtyItem, index) => (
                  <Flex mb={4} direction={'row'} key={index} justifyContent={'space-between'}>
                    <Box w="35%">
                      <FormControl
                        isInvalid={
                          formik?.touched?.specialties?.length > 0 &&
                          formik?.touched?.specialties[index]?.specialty &&
                          Boolean(
                            formik?.errors?.specialties?.length > 0 && formik?.errors?.specialties[index]?.specialty,
                          )
                        }
                      >
                        <FormLabel color={TITLE_GREY} fontSize={12}>
                          Specialty <span style={{ color: ERROR_RED }}>*</span>
                        </FormLabel>
                        <Select
                          value={specialtyItem?.specialty}
                          onChange={(e) => handleUserSpecialtiesChange(e, index)}
                          placeholder="Select"
                        >
                          {userSpecialtiesOptions?.map((item, ind) => (
                            <option key={ind} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          {formik?.errors?.specialties?.length > 0 && formik?.errors?.specialties[index]?.specialty}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box w="35%">
                      <FormControl
                        isInvalid={
                          formik?.touched?.specialties?.length > 0 &&
                          formik?.touched?.specialties[index]?.role &&
                          Boolean(formik?.errors?.specialties?.length > 0 && formik?.errors?.specialties[index]?.role)
                        }
                      >
                        <FormLabel color={TITLE_GREY} fontSize={12}>
                          Role <span style={{ color: ERROR_RED }}>*</span>
                        </FormLabel>
                        <Select
                          value={specialtyItem.role}
                          onChange={(e) => handleUserRolesChange(e, index)}
                          placeholder="Select"
                        >
                          {userRoleOptions?.map((item, ind) => (
                            <option key={ind} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          {formik?.errors?.specialties?.length > 0 && formik?.errors?.specialties[index]?.role}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box w="20%">
                      <FormControl>
                        <FormLabel color={TITLE_GREY} fontSize={12}>
                          Delete
                        </FormLabel>
                        <Circle
                          onClick={() => handleSpecialtyDelete(index)}
                          mt={4}
                          size="15px"
                          bg="red.500"
                          color="white"
                          _hover={{ bg: 'red.600' }}
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          padding={3}
                        >
                          <Icon as={CloseIcon} boxSize={2} />
                        </Circle>
                      </FormControl>
                    </Box>
                  </Flex>
                ))}
              </Grid>
              <Button
                leftIcon={<AddIcon boxSize={3} color={ADD_GREEN} />}
                variant="ghost"
                fontSize={14}
                fontWeight={400}
                onClick={handleAddAnotherSpecialtyClick}
              >
                Add another specialty
              </Button>
            </GridItem>
          </Grid>
        </Paper>
      </form>
    </Box>
  );
};

// #endregion

export default CreateUser;
