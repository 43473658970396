import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import UserListTable from './UserList';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import CreateUser from './CreateUser';
import EditUser from './EditUser';

/**
 *
 */
const UserlistApp = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <UserListTable />,
    },
    { path: '/create-user', element: <CreateUser /> },
    { path: '/edit-user/:userId', element: <EditUser /> },
  ]);
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
};
// #endregion

export default UserlistApp;
