/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const {
  UPDATE_CRUD_OPERATION,
  FETCH_FACILITIES,
  FETCH_SPECIALITIES,
  FETCH_ANNOTATIONS,
  FETCH_TAGS,
  FETCH_SPECIALTY_TAGS,
  UPDATE_SELECTED_FACILITY,
  UPDATE_SELECTED_SPECIALTY,
  PAGINATE,
} = require('../events');
const PlusSVG = require('../../assets/plus.svg');
const ContextualSVG = require('../../assets/contextual.svg');
const CloseButtonSVG = require('../../assets/close-btn.svg');
const eventBus = require('../utils/EventBus');
const setScrollPosition = require('../utils/setScrollPosition');
const { ITEMS_PER_PAGE } = require('../utils/constants');
const clinicDefinitionAPI = new ClinicDefinitionAPI();

class TagsAnnotations extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
    this._useScroll = true;
    this.offset = ITEMS_PER_PAGE;
  }

  attachEvents() {
    const clinicDefinitionService = new ClinicDefinitionAPI();
    const enableAllToggle = this.shadowRoot.querySelector('.toggle-all-wrapper');
    const tags = this.shadowRoot.querySelectorAll('.item-overlay');
    const showEnabledToggle = this.shadowRoot.querySelector('.show-enabled');

    const handleEnableAllClick = async () => {
      try {
        if (JSON.parse(enableAllToggle.getAttribute('enabled'))) {
          await clinicDefinitionService
            .facilities({ facilityId: this.selectedFacility })
            .specialties({ specialtyId: this.selectedSpecialty })
            .mapTags()
            .disableAll();

          enableAllToggle.enabled = false;
          eventBus.fire(FETCH_TAGS, {
            data: await clinicDefinitionService.globalTags().getAll(),
          });
          eventBus.fire(FETCH_SPECIALTY_TAGS, {
            data: await clinicDefinitionService
              .facilities({ facilityId: this.selectedFacility })
              .specialties({ specialtyId: this.selectedSpecialty })
              .mapTags()
              .getAll(),
          });
        } else {
          await clinicDefinitionService
            .facilities({ facilityId: this.selectedFacility })
            .specialties({ specialtyId: this.selectedSpecialty })
            .mapTags()
            .enableAll();

          enableAllToggle.enabled = true;
          eventBus.fire(FETCH_TAGS, {
            data: await clinicDefinitionService.globalTags().getAll(),
          });
          eventBus.fire(FETCH_SPECIALTY_TAGS, {
            data: await clinicDefinitionService
              .facilities({ facilityId: this.selectedFacility })
              .specialties({ specialtyId: this.selectedSpecialty })
              .mapTags()
              .getAll(),
          });
        }
      } catch (e) {
        enableAllToggle.enabled = !enabledAllToggle.enabled;
        return e;
      }
    };

    if (enableAllToggle) {
      enableAllToggle.addEventListener('click', handleEnableAllClick);
    }

    if (tags && this._data && this._data.tags) {
      this.formattedTagsArray = this._data.tags.results.map((tag) => ({
        ...tag,
        enabled: this._data.specialty_tags.some((item) => tag.id === item.id),
      }));
      tags.forEach((tag) => {
        const handleTagClick = async () => {
          if (JSON.parse(tag.getAttribute('enabled'))) {
            try {
              await clinicDefinitionService
                .facilities({ facilityId: this.selectedFacility })
                .specialties({ specialtyId: this.selectedSpecialty })
                .mapTags({ tagId: tag.id })
                .delete({
                  payload: {
                    id: tag.id,
                  },
                });

              tag.enabled = false;
              eventBus.fire(FETCH_TAGS, {
                data: await clinicDefinitionService.globalTags().getAll(),
              });
              eventBus.fire(FETCH_SPECIALTY_TAGS, {
                data: await clinicDefinitionService
                  .facilities({ facilityId: this.selectedFacility })
                  .specialties({ specialtyId: this.selectedSpecialty })
                  .mapTags()
                  .getAll(),
              });
            } catch (e) {
              tag.enabled = true;
              return e;
            }
          } else {
            const result = await clinicDefinitionService
              .facilities({ facilityId: this.selectedFacility })
              .specialties({ specialtyId: this.selectedSpecialty })
              .mapTags({ tagId: tag.id })
              .create({
                payload: {
                  global_tag_id: tag.id,
                },
              });
            if (result) {
              tag.enabled = true;
            }

            eventBus.fire(FETCH_TAGS, {
              data: await clinicDefinitionService.globalTags().getAll(),
            });
            eventBus.fire(FETCH_SPECIALTY_TAGS, {
              data: await clinicDefinitionService
                .facilities({ facilityId: this.selectedFacility })
                .specialties({ specialtyId: this.selectedSpecialty })
                .mapTags()
                .getAll(),
            });
          }
        };

        tag.addEventListener('click', handleTagClick);
      });
    }

    if (showEnabledToggle) {
      showEnabledToggle.addEventListener('click', () => {
        this.showEnabled = this.showEnabled === 'true' ? 'false' : 'true';
      });
    }
  }

  async connectedCallback() {
    this.formattedTagsArray = [];
    eventBus.register(UPDATE_CRUD_OPERATION, ({ detail }) => {
      this.selectedCard = detail.card;
      this.operation = detail.operation;
    });

    eventBus.register(UPDATE_SELECTED_FACILITY, ({ detail }) => {
      this.selectedFacility = detail.facilityId;
      this.selectedSpecialty = null;
    });

    eventBus.register(UPDATE_SELECTED_SPECIALTY, ({ detail }) => {
      this.selectedSpecialty = detail.specialtyId;
      this.offset = ITEMS_PER_PAGE;
    });

    eventBus.register(PAGINATE, async ({ detail }) => {
      this.tagListPosition = detail.listPosition;
      this.fetching = true;

      const clinicDefinitionService = new ClinicDefinitionAPI();
      const tagsData = await clinicDefinitionService.globalTags().getAll();

      if (tagsData.results.length && !this._data.tags.results.find((tag) => tag.id === tagsData.results[0].id)) {
        this._data.tags.results = [...this._data.tags.results, ...tagsData.results];

        this.offset = this.offset + tagsData.results.length;
      }

      this.fetching = false;
    });

    this.listenForEvents(
      [
        { event: FETCH_FACILITIES, key: 'facilities' },
        { event: FETCH_SPECIALITIES, key: 'specialities' },
        { event: FETCH_TAGS, key: 'tags' },
        { event: FETCH_ANNOTATIONS, key: 'annotations' },
        { event: FETCH_SPECIALTY_TAGS, key: 'specialty_tags' },
      ],
      eventBus,
    );

    eventBus.fire(FETCH_FACILITIES, {
      data: await clinicDefinitionAPI.facilities().get({
        parameters: {
          is_enabled: true,
        },
      }),
    });
  }

  static get observedAttributes() {
    return ['selectedcard', 'operation', 'selectedfacility', 'selectedspecialty', 'fetching', 'showenabled'];
  }

  set selectedCard(newSelectedCard) {
    this.setAttribute('selectedCard', newSelectedCard);
  }

  get selectedCard() {
    return this.getAttribute('selectedCard') || 'facilities';
  }

  set selectedFacility(newSelectedFacility) {
    this.setAttribute('selectedFacility', newSelectedFacility);
  }

  get selectedFacility() {
    return this.getAttribute('selectedFacility');
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  get selectedSpecialty() {
    return this.getAttribute('selectedSpecialty');
  }

  set selectedSpecialty(newSelectedSpecialty) {
    this.tagListPosition = 0;
    this.setAttribute('selectedSpecialty', newSelectedSpecialty);
  }

  get fetching() {
    return this.getAttribute('fetching');
  }

  set fetching(newFetching) {
    this.setAttribute('fetching', newFetching);
  }

  get tagListPosition() {
    return this.getAttribute('tagListPosition');
  }

  set tagListPosition(newPosition) {
    this.setAttribute('tagListPosition', newPosition);
  }

  get formattedTagsArray() {
    return JSON.parse(this.getAttribute('formattedTagsArray'));
  }

  set formattedTagsArray(newTagsArray) {
    this.setAttribute('formattedTagsArray', JSON.stringify(newTagsArray));
  }

  set showEnabled(newShowEnabled) {
    this.setAttribute('showEnabled', newShowEnabled);
  }

  get showEnabled() {
    return this.getAttribute('showEnabled');
  }

  handleScroll() {
    const tagsList = this.shadowRoot.querySelector('.tagList');

    if (tagsList) {
      tagsList.addEventListener('scroll', (event) => {
        this.tagListPosition = setScrollPosition(event);
      });
      tagsList.scrollTo({ top: this.tagListPosition });
    }
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .buttons {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
      }

      .toggle-all-wrapper {
        position: absolute;
        top: 0;
        right: 23px;
        height: 28px;
        width: 50px;
        background: transparent;
        border: none;
        cursor: pointer;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      exo-card-button {
        position: relative;
      }

      .enable-all-label {
        margin-top: 3%;
        margin-right: 10%;
      }

      .show-enabled-container {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }

      .show-enabled-label { 
        font-size: 12px;
        font-weight: 400;
        color: rgba(44, 44, 46, 0.503087);
        margin-right: 4px;
      }
    
      exo-card-button:last-child {
        margin-left: 10%;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      .card-container {
        display: flex;
      }

      exo-card {
        width: 25%;
      }

      exo-card:first-child {
        margin-left: 0%;
      }

      ${
        !this.selectedSpecialty
          ? `
          exo-card:nth-last-child(even) {
            margin-left: 2%;
          }
        `
          : `
          exo-card:nth-child(2) {
            margin-left: 2%;
          }

          exo-card:nth-child(3) {
            margin-left: 2%;
          }
        `
      }
      
      .item-wrapper {
        position: relative;
      }

      .item-overlay {
        background: transparent;
        border: none;
        height: 40px;
        width: 55px;
        position: absolute;
        top: 0;
        right: 6%;
        opacity: 0.2;
        cursor: pointer;
      }

      exo-card-list-item:hover {
        cursor: pointer;
      }

      .loading {
        font-size: 12px;
      }

      .toggle {
        white-space: nowrap;
        display: flex;
      }

      .card-content {
        height: 80vh;
        display: flex;
        flex-direction: column;
      }

      ul {
        overflow: auto;
      }

      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        exo-card {
          width: 30%;
        }
      }

      @media only screen and (max-width: 768px) {
        exo-card {
          width: 50%;
        }
      }
    `;
  }

  isEnabled(item) {
    return Boolean(
      this._data && this._data.specialty_tags && this._data.specialty_tags.find((tag) => tag.id === item.id),
    );
  }

  renderExoCard({ title, divider, items, card, operation, nameKey, selectedItem, totalCount, pagination }) {
    if (card === 'tags' && this.showEnabled == 'true') {
      items = items.filter((item) => this.isEnabled(item));
    }

    return `
      <exo-card
        class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''}"
        ${pagination ? `pagination totalCount=${totalCount} itemscount=${items.length} offset=${items.length}` : ''} >
        <div class="card-content">
          <div class="head-section ${card}">
            <exo-card-title title="${title}"></exo-card-title>
            ${
              card === 'annotations'
                ? `<div class="buttons">
                <exo-card-button class="add" card="${card}" facilityId="${this.selectedFacility}" specialtyId="${
                    this.selectedSpecialty
                  }">
                  <svg width="15" height="15" class="button-icon">
                    <image xlink:href="${PlusSVG}" width="15" height="15" />
                  </svg>
                </exo-card-button>
                <exo-card-button card="${card}" withMenu settings operation="${operation}">
                  <svg width="15" height="15" class="button-icon">
                    <image xlink:href="${
                      operation === 'view' ? ContextualSVG : CloseButtonSVG
                    }" width="15" height="15" />
                  </svg>
                </exo-card-button>
              </div>`
                : ''
            }
            ${
              card === 'tags'
                ? `<div class="show-enabled-container">
                <span class="show-enabled-label">Show Enabled</span>
                <exo-toggle
                  enabled="${this.showEnabled}"
                  class="show-enabled"
                ></exo-toggle>
              </div>`
                : ''
            }            
          </div>

          <div class="divider">
            ${divider.map((d) => `<span>${d}</span>`).join('')}
          </div>

          <ul class="items ${card === 'tags' ? 'tagList paginated' : ''}">
            ${items
              .map(
                (item) =>
                  `<div ${card === 'tags' ? 'class="item-wrapper"' : ''}>
                    <exo-card-list-item
                      page="tags"
                      name="${card === 'tags' ? item.tag : item.name}"
                      operation="${operation}"
                      id="${item.id}"
                      ${card === 'tags' ? 'class="tag-item"' : ''}
                      ${card === 'tags' ? '' : 'viewOnly'}
                      card="${card}"
                      model="${item.model}"
                      ${card === 'tags' ? `enabled="${this.isEnabled(item)}"` : ''}
                      facilityId="${this.selectedFacility}"
                      specialtyId="${this.selectedSpecialty}"
                      selectedItem="${selectedItem}"
                      ${card === 'tags' ? `tag="${item['tag']}"` : ''}
                      ${card !== 'tags' ? `clickable="true"` : ''}
                    >
                        ${item[card === 'tags' ? 'tag' : nameKey]}
                    </exo-card-list-item>
                    ${
                      card === 'tags'
                        ? `<button class="item-overlay" id="${item.id}" enabled="${JSON.parse(this.isEnabled(item))}"
                  }/>`
                        : ''
                    }
                  </div>`,
              )
              .join('')}
          </ul>

          ${
            (card === 'tags' || card === 'annotations') && operation === 'view' && items.length > 0
              ? `
              <div class="item-wrapper">
                <div class="enable-all" style="${this.showEnabled === 'true' ? 'display: none;' : 'display:;'}" >
                    <div>
                      ${card === 'tags' && this.fetching === 'true' ? '<div class="loading">Loading...</div>' : ''}
                    </div>
                    
                    <div class="toggle">
                      <span class="enable-all-label">${
                        items.every((item) => this._data.specialty_tags.find((tag) => tag.id === item.id))
                          ? 'Disable All'
                          : 'Enable All'
                      }</span>
                      <exo-toggle
                        card="${card}"
                        facilityId="${this.selectedFacility}"
                        specialtyId="${this.selectedSpecialty}"
                        all
                        enabled="${items.every((item) => this._data.specialty_tags.find((tag) => tag.id === item.id))}"
                      ></exo-toggle>
                  </div>
                </div>
                <button class="toggle-all-wrapper" enabled="${items.every((item) =>
                  this._data.specialty_tags.find((tag) => tag.id === item.id),
                )}" />
                </div>`
              : ''
          }
          </div>
      </exo-card>
    `;
  }

  render() {
    return `
      <div>
        <div class="title">Map Tags</div>
        <div class="card-container">
          ${
            this._data && this._data.facilities
              ? this.renderExoCard({
                  card: 'facilities',
                  title: 'Facility',
                  divider: ['Facility'],
                  nameKey: 'name',
                  operation: this.selectedCard === 'facilities' ? this.operation : 'view',
                  items: this._data.facilities,
                  selectedItem: this.selectedFacility,
                })
              : `<span> Loading... </span>`
          }
          ${
            this._data && this._data.specialities
              ? this.renderExoCard({
                  card: 'specialities',
                  title: 'Specialities',
                  nameKey: 'name',
                  divider: ['Specialities'],
                  operation: this.selectedCard === 'specialities' ? this.operation : 'view',
                  items: this._data.specialities,
                  selectedItem: this.selectedSpecialty,
                })
              : ''
          }
          ${
            this._data && parseInt(this.selectedSpecialty) && this._data.tags
              ? this.renderExoCard({
                  card: 'tags',
                  title: 'Tags',
                  nameKey: 'name',
                  divider: ['Tag', 'Enabled'],
                  operation: this.selectedCard === 'tags' ? this.operation : 'view',
                  items: this._data.tags.results,
                  totalCount: this._data.tags.count,
                  pagination: this.showEnabled !== 'true',
                })
              : ''
          }
        </div>
      </div>
    `;
  }
}

customElements.define('tags-annotations-page', TagsAnnotations);
